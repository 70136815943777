import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Popover } from "antd";

const UniversityTableCols = (handleUpdate: any, handleDelete: any) => [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "University Name",
    dataIndex: "name",
    key: "name",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Actions",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return (
        <div className="flex flex-row items-center gap-x-4 hover:cursor-pointer">
          <Popover content={<p>Edit Major</p>}>
            <EditOutlined
              className="text-blue-600 min-w-[14px] min-h-[14px]"
              onClick={() => handleUpdate(value)}
            />
          </Popover>
          <Popover content={<p>Delete Major</p>}>
            <DeleteOutlined
              className="text-red-600 min-w-[14px] min-h-[14px]"
              onClick={() => handleDelete(value)}
            />
          </Popover>
        </div>
      );
    },
  },
];

export default UniversityTableCols;
