import { PlusOutlined } from "@ant-design/icons";
import { Input, Modal, notification, Table, Tabs, TabsProps } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import UniversityTableCols from "components/table-columns/UniversityTableCols";
import { useMutation } from "@tanstack/react-query";
import { createItemService } from "services/createItemService";
import { DELETE_UNIVERSITY_ENDPOINT, GET_UNIVERSITIES_ENDPOINT } from "api/apiUrls";
import { CREATE_UNIVERSITY_PATH } from "Routes/paths";
import { deleteItemService } from "services/deleteItemService";
import { BaseResponse } from "../../../constants";

export default function Universities() {
  const navigate = useNavigate();

  const { Search } = Input;

  const [searchParams, setSearchParams] = useSearchParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [deletingId, setDeletingId] = useState<number | null>(null);

  const deleteMutation = useMutation({
    mutationFn: () =>
      deleteItemService(
        DELETE_UNIVERSITY_ENDPOINT(deletingId?.toString() ?? "1")
      ).then((res) => res),
    onSuccess: (data: BaseResponse) => {
      if (data.code === 1) {
        notification.error({ message: data.message });
      } else {
        notification.success({ message: data.message });
      }
    },
    onSettled: () => {
      setModalOpen(false);
      universitiesQuery.mutate();
    },
  });

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Universities",
      children: <></>,
    },
  ];

  const PAGE_SIZE = 8;

  const [universities, setUniversities] = useState<any>([]);
  const [totalItems, setTotalItems] = useState(0);
  const universitiesQuery = useMutation({
    mutationFn: () =>
      createItemService(GET_UNIVERSITIES_ENDPOINT, {
        pageNumber: Number(searchParams.get("page") ?? "1") - 1,
        pageSize: PAGE_SIZE,
        sortingExpression: "",
        sortingDirection: 0,
        universityName: searchParams.get("name") ?? "",
      }).then((res) => res),
  });

  useEffect(() => {
    universitiesQuery.mutate();
  }, [searchParams]);

  useEffect(() => {
    if (universitiesQuery.data) {
      setUniversities(universitiesQuery.data?.data?.dataList);
      setTotalItems(universitiesQuery.data?.data?.totalCount);
    }
  }, [universitiesQuery.data]);

  return (
    <section className="w-full h-full p-6 flex flex-col items-start gap-4 relative">
      <Search
        className="!h-[2.5rem] !w-[18rem] scrn-840:hidden"
        placeholder="Search by id, name, or email"
        onSearch={(value) =>
          setSearchParams({
            page: searchParams.get("page") ?? "1",
            name: value,
          })
        }
      />
      <div className="w-full rounded-lg shadow-md bg-white pb-2">
        <div className="w-full h-[4rem] px-4 bg-[#F9FAFB] border border-[#E6E8EC] flex flex-row items-end justify-between rounded-tr-lg rounded-tl-lg">
          <Tabs defaultActiveKey="1" items={items} />
          <div className="h-[4rem] hidden scrn-840:flex items-center justify-center gap-x-3">
            <Search
              className="!h-[2.5rem] !w-[18rem]"
              placeholder="Search by id, name, or email"
              onSearch={(value) =>
                setSearchParams({
                  page: searchParams.get("page") ?? "1",
                  name: value,
                })
              }
            />
            <button
              onClick={() => navigate(CREATE_UNIVERSITY_PATH)}
              className="w-[2.5rem] h-[2.5rem] rounded-lg flex items-center justify-center bg-primary text-white"
            >
              <PlusOutlined className="font-bold text-white" />
            </button>
          </div>
        </div>
        <Table
          className="m-4 shadow-md overflow-x-auto"
          dataSource={universities}
          loading={universitiesQuery.isPending}
          pagination={{
            current: Number(searchParams.get("page") ?? "1"),
            pageSize: 8,
            total: totalItems,
            showSizeChanger: false,
            onChange(page, pageSize) {
              setSearchParams({
                page: page.toString(),
                name: searchParams.get("name") ?? "",
              });
            },
          }}
          columns={UniversityTableCols(
            (value: any) => {
              navigate(`/dashboard/universities/${value}/edit`);
            },
            (value: any) => {}
          )}
        />
      </div>
      <Modal
        title="Delete Confirmation"
        open={modalOpen}
        onOk={async () => {
          deleteMutation.mutate();
        }}
        onCancel={() => setModalOpen(false)}
        okButtonProps={{
          className: "bg-[red]",
        }}
        confirmLoading={deleteMutation.isPending}
      >
        <p>Are you sure you want to delete: {deletingId}</p>
      </Modal>
    </section>
  );
}
