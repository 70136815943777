import api from "api";
import store from "redux/store";

export const createItemService = async (url: string, data: any, contentType?: "multipart/form-data" | "application/json") => {
    const response = await api.post(url, data, {
        headers: {
            "Authorization": `Bearer ${store.getState()?.authReducer?.user?.accessToken}`,
            "Content-Type": contentType ? contentType : "application/json"
        }
    });

    return response.data;
}