import { Navigate } from "react-router";
import {
  ASSIGN_PLAN_PATH,
  CHANGE_COMPANIES_STATUS_PATH,
  CREATE_MAJOR_PATH,
  CREATE_SUBSCRIPTION_PLAN_PATH,
  CREATE_UNIVERSITY_PATH,
  EDIT_MAJOR_PATH,
  EDIT_SUBSCRIPTION_PLAN_PATH,
  EDIT_UNIVERSITY_PATH,
  GET_COMPANIES_PATH,
  LOGIN_PATH,
  MAJORS_PATH,
  SUBSCRIPTION_PLANS_PATH,
  UNIVERSITIES_PATH,
} from "Routes/paths";
import Login from "screens/Login";
import DashboardWrapper from "screens/Dashboard/DashboardWrapper";
import SubscriptionPlans from "screens/Dashboard/SubscriptionPlans/SubscriptionPlans";
import CreateSubscriptionPlan from "screens/Dashboard/SubscriptionPlans/CreateSubscriptionPlan";
import EditSubscriptionPlan from "screens/Dashboard/SubscriptionPlans/EditSubscriptionPlan";
import Majors from "screens/Dashboard/Majors/Majors";
import CreateMajor from "screens/Dashboard/Majors/CreateMajor";
import EditMajor from "screens/Dashboard/Majors/EditMajor";
import Universities from "screens/Dashboard/Universities/Universities";
import CreateUniversity from "screens/Dashboard/Universities/CreateUniversity";
import EditUniversity from "screens/Dashboard/Universities/EditUniversity";
import Companies from "screens/Dashboard/Companies/Companies";
import ChangeAcceptanceStatus from "screens/Dashboard/Companies/ChangeAcceptanceStatus";
import AssignSubscriptionPlan from "screens/Dashboard/Companies/AssignSubscriptionPlan";

type RoutesType = {
  path: string;
  element: any;
  parent?: any;
};

export const routes: RoutesType[] = [
  {
    path: "/",
    element: <Navigate to="/login" replace />,
  },
  {
    path: LOGIN_PATH,
    element: <Login />,
  },
  {
    path: SUBSCRIPTION_PLANS_PATH,
    element: <SubscriptionPlans />,
    parent: <DashboardWrapper />,
  },
  {
    path: CREATE_SUBSCRIPTION_PLAN_PATH,
    element: <CreateSubscriptionPlan />,
    parent: <DashboardWrapper />,
  },
  {
    path: EDIT_SUBSCRIPTION_PLAN_PATH,
    element: <EditSubscriptionPlan />,
    parent: <DashboardWrapper />,
  },
  {
    path: MAJORS_PATH,
    element: <Majors />,
    parent: <DashboardWrapper />,
  },
  {
    path: CREATE_MAJOR_PATH,
    element: <CreateMajor />,
    parent: <DashboardWrapper />,
  },
  {
    path: EDIT_MAJOR_PATH,
    element: <EditMajor />,
    parent: <DashboardWrapper />,
  },
  {
    path: UNIVERSITIES_PATH,
    element: <Universities />,
    parent: <DashboardWrapper />,
  },
  {
    path: CREATE_UNIVERSITY_PATH,
    element: <CreateUniversity />,
    parent: <DashboardWrapper />,
  },
  {
    path: EDIT_UNIVERSITY_PATH,
    element: <EditUniversity />,
    parent: <DashboardWrapper />,
  },
  {
    path: GET_COMPANIES_PATH,
    element: <Companies />,
    parent: <DashboardWrapper />,
  },
  {
    path: CHANGE_COMPANIES_STATUS_PATH,
    element: <ChangeAcceptanceStatus />,
    parent: <DashboardWrapper />,
  },
  {
    path: ASSIGN_PLAN_PATH,
    element: <AssignSubscriptionPlan />,
    parent: <DashboardWrapper />,
  },
];
