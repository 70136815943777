import api from "api";
import store from "redux/store";

export const getItemsService = async (url: string) => {
    const response = await api.get(url, {
        headers: {
            "Authorization": `Bearer ${store.getState()?.authReducer?.user?.accessToken}`
        }
    });

    return response.data;
}