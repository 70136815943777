import {
  LOGIN_SUCCESS,
  LOG_OUT
} from 'redux/types';
  
const initialState = {
  user: null
};
  
export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {...state, user: action.payload};
    case LOG_OUT:
      return {...state, user: null};
    default:
      return state;
  }
};
  