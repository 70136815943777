import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import Sidebar from "components/ui/Sidebar";
import Navbar from "components/ui/Navbar";
import { PageBg } from "assets/images";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { LOGIN_PATH } from "Routes/paths";

export default function DashboardWrapper() {
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.authReducer);

  useEffect(() => {
    if (user === null || user === undefined) {
      navigate(LOGIN_PATH);
    }
  }, [user]);

  return (
    <main className="w-full h-screen relative flex">
      <Navbar />
      <Sidebar />
      <section
        className="w-full h-full pt-[80px] scrn-1024:pl-[300px]"
        style={{
          backgroundImage: `url('${PageBg}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Outlet />
      </section>
    </main>
  );
}
