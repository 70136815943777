import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import {
  BaseResponse,
  EMPTY_FIELD_ERR_MSG,
  SUBSCRIPTION_PLAN_DURATION_OPTIONS,
} from "../../../constants";
import { Input, notification, Select } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { createItemService } from "services/createItemService";
import { CREATE_SUBSCRIPTION_PLAN_ENDPOINT } from "api/apiUrls";
import { useNavigate } from "react-router";

interface CreateSubscriptionPlanSchema {
  subscriptionPlanName: string;
  cvUnlocksAmount: number;
  jobPostingsAmount: number;
  duration: number | null;
  price: number;
}

export default function CreateSubscriptionPlanForm() {
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: (values: CreateSubscriptionPlanSchema) =>
      createItemService(
        CREATE_SUBSCRIPTION_PLAN_ENDPOINT,
        values,
        "application/json"
      ).then((res) => res),
    onSuccess: (data: BaseResponse) => {
      if (data.code === 1) {
        notification.error({ message: data.message });
      } else {
        notification.success({ message: data.message });
        navigate(-1);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      subscriptionPlanName: "",
      cvUnlocksAmount: 0,
      jobPostingsAmount: 0,
      duration: null,
      price: 0,
    },
    validationSchema: yup.object({
      subscriptionPlanName: yup.string().min(3).required(EMPTY_FIELD_ERR_MSG),
      cvUnlocksAmount: yup.number().positive().required(EMPTY_FIELD_ERR_MSG),
      jobPostingsAmount: yup.number().positive().required(EMPTY_FIELD_ERR_MSG),
      duration: yup.number().required(EMPTY_FIELD_ERR_MSG),
      price: yup.number().positive().required(EMPTY_FIELD_ERR_MSG),
    }),
    onSubmit: (values: CreateSubscriptionPlanSchema) => {
      mutation.mutate(values);
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="w-full flex flex-col items-start gap-4"
    >
      <div className="w-full grid grid-cols-1 scrn-767:grid-cols-2 gap-3">
        <div className="w-full flex flex-col items-start gap-1">
          <label>Plan Name*</label>
          <Input
            name="subscriptionPlanName"
            value={formik.values.subscriptionPlanName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            status={
              formik.touched.subscriptionPlanName &&
              formik.errors.subscriptionPlanName
                ? "error"
                : ""
            }
            className="min-h-[2.6rem]"
            placeholder="Plan Name"
          />
          {formik.touched.subscriptionPlanName &&
            formik.errors.subscriptionPlanName && (
              <p className="text-red-500 text-sm">
                {formik.errors.subscriptionPlanName}
              </p>
            )}
        </div>
        <div className="w-full flex flex-col items-start gap-1">
          <label>Cv Unlocks Amount*</label>
          <Input
            name="cvUnlocksAmount"
            value={formik.values.cvUnlocksAmount}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="number"
            status={
              formik.touched.cvUnlocksAmount && formik.errors.cvUnlocksAmount
                ? "error"
                : ""
            }
            className="min-h-[2.6rem]"
            placeholder="Cv Unlocks Amount"
          />
          {formik.touched.cvUnlocksAmount && formik.errors.cvUnlocksAmount && (
            <p className="text-red-500 text-sm">
              {formik.errors.cvUnlocksAmount}
            </p>
          )}
        </div>
      </div>
      <div className="w-full grid grid-cols-1 scrn-767:grid-cols-2 gap-3">
        <div className="w-full flex flex-col items-start gap-1">
          <label>Job Postings Amount*</label>
          <Input
            name="jobPostingsAmount"
            value={formik.values.jobPostingsAmount}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="number"
            status={
              formik.touched.jobPostingsAmount &&
              formik.errors.jobPostingsAmount
                ? "error"
                : ""
            }
            className="min-h-[2.6rem]"
            placeholder="Job Postings Amount"
          />
          {formik.touched.jobPostingsAmount &&
            formik.errors.jobPostingsAmount && (
              <p className="text-red-500 text-sm">
                {formik.errors.jobPostingsAmount}
              </p>
            )}
        </div>
        <div className="w-full flex flex-col items-start gap-1">
          <label>Plan Duration*</label>
          <Select
            onBlur={() => formik.setFieldTouched("duration", true)}
            onChange={(value) => formik.setFieldValue("duration", value)}
            status={
              formik.touched.duration && formik.errors.duration ? "error" : ""
            }
            options={SUBSCRIPTION_PLAN_DURATION_OPTIONS.map(
              (item: { label: string; value: number }) => {
                return {
                  label: item.label,
                  value: item.value,
                };
              }
            )}
            className="min-h-[2.6rem] w-full"
            placeholder="Plan Duration"
          />
          {formik.touched.duration &&
            formik.errors.duration && (
              <p className="text-red-500 text-sm">
                {formik.errors.duration}
              </p>
            )}
        </div>
      </div>
      <div className="w-full grid grid-cols-1 scrn-767:grid-cols-2 gap-3">
        <div className="w-full flex flex-col items-start gap-1">
          <label>Plan Price*</label>
          <Input
            name="price"
            value={formik.values.price}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="number"
            status={
              formik.touched.price &&
              formik.errors.price
                ? "error"
                : ""
            }
            className="min-h-[2.6rem]"
            placeholder="Plan Price"
          />
          {formik.touched.price &&
            formik.errors.price && (
              <p className="text-red-500 text-sm">
                {formik.errors.price}
              </p>
            )}
        </div>
      </div>
      <button
        type="submit"
        disabled={mutation.isPending}
        className="rounded-lg px-6 py-3 w-[8rem] bg-primary self-center text-white duration-100 hover:bg-primary/80"
      >
        {mutation.isPending ? (
          <Loading3QuartersOutlined className="animate-spin text-lg" />
        ) : (
          "Create"
        )}
      </button>
    </form>
  );
}
