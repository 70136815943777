import { CompaniesBlackIcon, CompaniesWhiteIcon, MajorBlackIcon, MajorWhiteIcon, SubscriptionPlansBlackIcon, SubscriptionPlansWhiteIcon, UniversityBlackIcon, UniversityWhiteIcon } from "assets/icons";

const EMPTY_FIELD_ERR_MSG = "Please enter this field";

export interface BaseResponse {
    code: 0 | 1;
    message: string;
    data: any;
}

const NAV_ITEMS = [
    {
        name: "Companies",
        route: "/dashboard/companies",
        icon: CompaniesWhiteIcon,
        selectedIcon: CompaniesBlackIcon
    },
    {
        name: "Subscription Plans",
        route: "/dashboard/subscription-plans",
        icon: SubscriptionPlansWhiteIcon,
        selectedIcon: SubscriptionPlansBlackIcon
    },
    {
        name: "Majors",
        route: "/dashboard/majors",
        icon: MajorWhiteIcon,
        selectedIcon: MajorBlackIcon
    },
    {
        name: "Universities",
        route: "/dashboard/universities",
        icon: UniversityWhiteIcon,
        selectedIcon: UniversityBlackIcon
    }
];

const SUBSCRIPTION_PLAN_DURATION_OPTIONS = [
    {
        label: "Monthly",
        value: 1
    },
    {
        label: "Quarterly",
        value: 2
    },
    {
        label: "Annualy",
        value: 3
    },
];

const COMPANY_ACCEPTANCE_STATUS_ID = [
    {
        label: "Pending",
        value: 1
    },
    {
        label: "Accepted",
        value: 2
    },
    {
        label: "Rejected",
        value: 3
    }
];

export {
    EMPTY_FIELD_ERR_MSG,
    NAV_ITEMS,
    SUBSCRIPTION_PLAN_DURATION_OPTIONS,
    COMPANY_ACCEPTANCE_STATUS_ID
}