import React from "react";
import CreateUniversityForm from "components/forms/university-forms/CreateUniversityForm";

export default function CreateUniversity() {
  return (
    <div className="w-full h-full p-4">
      <div className="w-full bg-white border shadow-md rounded-lg flex flex-col p-4 pb-6 gap-4">
        <h1 className="font-bold text-lg">Create University</h1>
        <CreateUniversityForm />
      </div>
    </div>
  );
}
