import { Input, notification, Spin } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { BaseResponse, EMPTY_FIELD_ERR_MSG } from "../../../constants";
import * as yup from "yup";
import { getItemsService } from "services/getItemsService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { EDIT_UNIVERSITY_ENDPOINT, GET_UNIVERSITY_ENDPOINT } from "api/apiUrls";
import { editItemService } from "services/editItemService";
import { useNavigate } from "react-router";

interface EditUniversitySchema {
  universityEnName: string;
  universityArName: string;
}

export default function EditUniversityForm({ id }: { id: string }) {
  const navigate = useNavigate();

  const [university, setUniversity] = useState<any>(null);
  const universityQuery = useQuery({
    queryKey: ["get-university", id],
    queryFn: () =>
      getItemsService(GET_UNIVERSITY_ENDPOINT(id)).then((res) => res),
  });

  const mutation = useMutation({
    mutationFn: (values: EditUniversitySchema) =>
      editItemService(
        EDIT_UNIVERSITY_ENDPOINT(id),
        values,
        "application/json"
      ).then((res) => res),
    onSuccess: (data: BaseResponse) => {
      if (data.code === 1) {
        notification.error({ message: data.message });
      } else {
        notification.success({ message: data.message });
        navigate(-1);
      }
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      universityEnName: university?.universityEnName,
      universityArName: university?.universityArName,
    },
    validationSchema: yup.object({
      universityEnName: yup.string().required(EMPTY_FIELD_ERR_MSG),
      universityArName: yup.string().required(EMPTY_FIELD_ERR_MSG),
    }),
    onSubmit: (values: EditUniversitySchema) => {
      mutation.mutate(values);
    },
  });

  useEffect(() => {
    if (universityQuery.data) {
      setUniversity(universityQuery.data?.data);
    }
  }, [universityQuery.data]);

  return (
    <Spin spinning={universityQuery.isFetching}>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full flex flex-col items-start gap-4"
      >
        <div className="w-full grid grid-cols-1 scrn-767:grid-cols-2 gap-3">
          <div className="w-full flex flex-col items-start gap-1">
            <label>University En Name*</label>
            <Input
              name="universityEnName"
              value={formik.values.universityEnName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              status={
                formik.touched.universityEnName &&
                formik.errors.universityEnName
                  ? "error"
                  : ""
              }
              className="min-h-[2.6rem]"
              placeholder="Major En Name"
            />
            {formik.touched.universityEnName &&
              formik.errors.universityEnName && (
                <p className="text-red-500 text-sm">
                  {formik.errors.universityEnName.toString()}
                </p>
              )}
          </div>
          <div className="w-full flex flex-col items-start gap-1">
            <label>University Ar Name*</label>
            <Input
              name="universityArName"
              value={formik.values.universityArName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              status={
                formik.touched.universityArName &&
                formik.errors.universityArName
                  ? "error"
                  : ""
              }
              className="min-h-[2.6rem]"
              placeholder="Major Ar Name"
            />
            {formik.touched.universityArName &&
              formik.errors.universityArName && (
                <p className="text-red-500 text-sm">
                  {formik.errors.universityArName.toString()}
                </p>
              )}
          </div>
        </div>
        <button
          type="submit"
          disabled={mutation.isPending}
          className="rounded-lg px-6 py-3 w-[8rem] bg-primary self-center text-white duration-100 hover:bg-primary/80"
        >
          {mutation.isPending ? (
            <Loading3QuartersOutlined className="animate-spin text-lg" />
          ) : (
            "Edit"
          )}
        </button>
      </form>
    </Spin>
  );
}
