import React from "react";
import EditMajorForm from "components/forms/major-forms/EditMajorForm";
import { useParams } from "react-router";

export default function EditMajor() {
  const { id } = useParams();

  return (
    <div className="w-full h-full p-4">
      <div className="w-full bg-white border shadow-md rounded-lg flex flex-col p-4 pb-6 gap-4">
        <h1 className="font-bold text-lg">Edit Major</h1>
        <EditMajorForm id={id ?? "1"} />
      </div>
    </div>
  );
}
