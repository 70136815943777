import React from "react";
import { useParams } from "react-router";
import EditSubscriptionPlanForm from "components/forms/subscription-plans/EditSubscriptionPlanForm";

export default function EditSubscriptionPlan() {
  const { id } = useParams();

  return (
    <div className="w-full h-full p-4">
      <div className="w-full bg-white border shadow-md rounded-lg flex flex-col p-4 pb-6 gap-4">
        <h1 className="font-bold text-lg">Edit Subscription Plan</h1>
        <EditSubscriptionPlanForm id={id ?? "1"} />
      </div>
    </div>
  );
}
