import { EditOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { SUBSCRIPTION_PLAN_DURATION_OPTIONS } from "../../constants";

const SubscriptionPlansTableCols = (handleUpdate: any) => [
  {
    title: "#",
    dataIndex: "subscriptionPlanId",
    key: "subscriptionPlanId",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Plan Name",
    dataIndex: "subscriptionPlanName",
    key: "subscriptionPlanName",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "CV Unlocks Amount",
    dataIndex: "cvUnlocksAmount",
    key: "cvUnlocksAmount",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Job Posting Amount",
    dataIndex: "jobPostingsAmount",
    key: "jobPostingsAmount",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
    render: (value: any) => {
      return (
        <p>
          {
            SUBSCRIPTION_PLAN_DURATION_OPTIONS.find(
              (item) => item.value === value
            )?.label
          }
        </p>
      );
    },
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    render: (value: number) => {
      return <p>{value?.toLocaleString("EGP")} EGP</p>;
    },
  },
  {
    title: "Actions",
    dataIndex: "subscriptionPlanId",
    key: "subscriptionPlanId",
    render: (value: any) => {
      return (
        <div className="flex flex-row items-center gap-x-4 hover:cursor-pointer">
          <Popover content={<p>Edit Plan</p>}>
            <EditOutlined
              className="text-blue-600 min-w-[14px] min-h-[14px]"
              onClick={() => handleUpdate(value)}
            />
          </Popover>
        </div>
      );
    },
  },
];

export default SubscriptionPlansTableCols;
