import api from "api";
import store from "redux/store";

export const deleteItemService = async (url: string) => {
    const response = await api.delete(url, {
        headers: {
            "Authorization": `Bearer ${store.getState()?.authReducer?.user?.accessToken}`,
        }
    });

    return response.data;
}