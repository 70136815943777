import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import {
  BaseResponse,
  COMPANY_ACCEPTANCE_STATUS_ID,
  EMPTY_FIELD_ERR_MSG,
} from "../../../constants";
import { notification, Select } from "antd";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { CHANGE_COMPANY_STATUS_ENDPOINT } from "api/apiUrls";
import { editItemService } from "services/editItemService";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";

interface ChangeCompanyAcceptanceStatusSchema {
  companyId: number;
  acceptanceStatusId: number | null;
}

export default function ChangeCompanyAcceptanceStatusForm({
  id,
}: {
  id: string;
}) {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const mutation = useMutation({
    mutationFn: (values: ChangeCompanyAcceptanceStatusSchema) =>
      editItemService(
        CHANGE_COMPANY_STATUS_ENDPOINT,
        values,
        "application/json"
      ).then((res) => res),
    onSuccess: (data: BaseResponse) => {
      if (data.code === 1) {
        notification.error({ message: data.message });
      } else {
        notification.success({ message: data.message });
        navigate(-1);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      companyId: parseInt(id),
      acceptanceStatusId: searchParams.get("acceptance_status_id")
        ? parseInt(searchParams.get("acceptance_status_id") ?? "1")
        : null,
    },
    validationSchema: yup.object({
      companyId: yup.number().required(EMPTY_FIELD_ERR_MSG),
      acceptanceStatusId: yup.number().required(EMPTY_FIELD_ERR_MSG),
    }),
    onSubmit: (values: ChangeCompanyAcceptanceStatusSchema) => {
      mutation.mutate(values);
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="w-full flex flex-col items-start gap-4"
    >
      <div className="w-full grid grid-cols-1 scrn-767:grid-cols-2 gap-3">
        <div className="w-full flex flex-col items-start gap-1">
          <label>Company Status*</label>
          <Select
            value={formik.values.acceptanceStatusId}
            onBlur={() => formik.setFieldTouched("acceptanceStatusId", true)}
            onChange={(value) =>
              formik.setFieldValue("acceptanceStatusId", value)
            }
            status={
              formik.touched.acceptanceStatusId &&
              formik.errors.acceptanceStatusId
                ? "error"
                : ""
            }
            options={COMPANY_ACCEPTANCE_STATUS_ID.filter(
              (item) => item.value !== 1
            ).map((item) => {
              return {
                label: item.label,
                value: item.value,
              };
            })}
            className="min-h-[2.6rem] w-full"
            placeholder="Company Status"
          />
          {formik.touched.acceptanceStatusId &&
            formik.errors.acceptanceStatusId && (
              <p className="text-red-500 text-sm">
                {formik.errors.acceptanceStatusId}
              </p>
            )}
        </div>
      </div>
      <button
        type="submit"
        disabled={mutation.isPending}
        className="rounded-lg px-6 py-3 w-[8rem] bg-primary self-center text-white duration-100 hover:bg-primary/80"
      >
        {mutation.isPending ? (
          <Loading3QuartersOutlined className="animate-spin text-lg" />
        ) : (
          "Submit"
        )}
      </button>
    </form>
  );
}
