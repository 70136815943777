import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { NAV_ITEMS } from "../../constants";

export default function Sidebar() {
  const [selectedItem, setSelectedItem] = useState("");

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const highlightNavbarItem = () => {
    switch (pathname) {
      case "/dashboard/companies":
        setSelectedItem("Companies");
        return;
      case "/dashboard/subscription-plans":
        setSelectedItem("Subscription Plans");
        return;
      case "/dashboard/majors":
        setSelectedItem("Majors");
        return;
      case "/dashboard/universities":
        setSelectedItem("Universities");
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    highlightNavbarItem();
  }, [pathname]);

  return (
    <aside
      className="w-[300px] bg-primary hidden scrn-1024:block fixed left-0 top-[80px] border-t-2"
      style={{
        height: "calc(100vh - 80px)",
      }}
    >
      <ul className="p-4 px-6 flex flex-col items-start gap-4 mt-5">
        {NAV_ITEMS.map((item: any, idx: number) => {
          return (
            <div
              key={idx}
              onClick={() => navigate(item.route)}
              className={`w-full h-[44px] px-4 flex flex-row items-center justify-start gap-x-6 rounded-md transition-all ${
                selectedItem === item.name ? "bg-white translate-x-2" : ""
              } hover:cursor-pointer hover:translate-x-2`}
            >
              <img
                src={selectedItem === item.name ? item.selectedIcon : item.icon}
                alt={`${item.name} icon`}
                className="w-[1.5rem] h-[1.5rem]"
              />
              <p
                className={
                  selectedItem === item.name ? "text-black" : "text-white"
                }
              >
                {item.name}
              </p>
            </div>
          );
        })}
      </ul>
    </aside>
  );
}
