import React, { useEffect, useState } from "react";
import { NAV_ITEMS } from "../../constants";
import { HamburgerMenuIcon, Logo } from "assets/icons";
import {
  CloseOutlined,
  DownOutlined,
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useLocation, useNavigate } from "react-router";
import { Popover } from "antd";
import { LOG_OUT } from "redux/types";

export default function Navbar() {
  const dispatch: any = useDispatch();

  const { user } = useSelector((state: RootState) => state.authReducer);

  const [menuOpen, setMenuOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState("");

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const highlightNavbarItem = () => {
    switch (pathname) {
      case "/dashboard/companies":
        setSelectedItem("Companies");
        return;
      case "/dashboard/subscription-plans":
        setSelectedItem("Subscription Plans");
        return;
      case "/dashboard/majors":
        setSelectedItem("Majors");
        return;
      case "/dashboard/universities":
        setSelectedItem("Universities");
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    highlightNavbarItem();
  }, [pathname]);

  return (
    <>
      <header className="w-full h-[80px] bg-primary fixed top-0 left-0 z-50">
        <nav className="w-full h-full flex items-center justify-between px-4">
          <div className="hidden scrn-1024:block">
            <img src={Logo} alt="logo" className="w-24" />
          </div>
          <button
            className="block scrn-1024:hidden"
            onClick={() => setMenuOpen((prev) => !prev)}
          >
            {menuOpen ? (
              <CloseOutlined className="text-2xl text-white" />
            ) : (
              <img src={HamburgerMenuIcon} alt="menu" className="w-10" />
            )}
          </button>
          <Popover
            trigger="click"
            content={
              <div className="w-full h-full flex flex-col">
                <button
                  type="button"
                  className="flex items-center gap-2"
                  onClick={() => dispatch({ type: LOG_OUT })}
                >
                  <PoweroffOutlined className="text-[red]" />
                  Log Out
                </button>
              </div>
            }
            className="hidden scrn-1024:flex"
          >
            <button
              type="button"
              className="w-12 h-12 bg-white items-center justify-center rounded-full"
            >
              <UserOutlined className="text-primary text-2xl" />
            </button>
          </Popover>
          <div className="block scrn-1024:hidden">
            <img src={Logo} alt="logo" className="w-24" />
          </div>
        </nav>
      </header>
      <div
        className={`mt-[80px] ${
          menuOpen ? "w-[300px]" : "w-0"
        } transition-all duration-150 bg-primary fixed left-0 top-0 z-40 overflow-hidden`}
        style={{
          height: "calc(100vh - 80px)",
        }}
      >
        <div className="w-full flex flex-col items-start gap-3 p-4">
          <ul className="w-full px-3 flex flex-col items-start gap-4 mt-5">
            {NAV_ITEMS.map((item: any, idx: number) => {
              return (
                <div
                  key={idx}
                  onClick={() => {
                    setMenuOpen(false);
                    navigate(item.route);
                  }}
                  className={`w-full h-[44px] px-4 flex flex-row items-center justify-start gap-x-6 rounded-md transition-all ${
                    selectedItem === item.name ? "bg-white translate-x-2" : ""
                  } hover:cursor-pointer hover:translate-x-2`}
                >
                  <img
                    src={
                      selectedItem === item.name ? item.selectedIcon : item.icon
                    }
                    alt={`${item.name} icon`}
                    className="w-[1.5rem] h-[1.5rem]"
                  />
                  <p
                    className={
                      selectedItem === item.name
                        ? "text-black font-bold"
                        : "text-white"
                    }
                  >
                    {item.name}
                  </p>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
