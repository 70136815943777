export const LOGIN_PATH = "/login";

const DASHBOARD_PATH = "/dashboard";

export const SUBSCRIPTION_PLANS_PATH = `${DASHBOARD_PATH}/subscription-plans`;
export const CREATE_SUBSCRIPTION_PLAN_PATH = `${DASHBOARD_PATH}/subscription-plans/create`;
export const EDIT_SUBSCRIPTION_PLAN_PATH = `${DASHBOARD_PATH}/subscription-plans/:id/edit`;

export const MAJORS_PATH = `${DASHBOARD_PATH}/majors`;
export const CREATE_MAJOR_PATH = `${DASHBOARD_PATH}/majors/create`;
export const EDIT_MAJOR_PATH = `${DASHBOARD_PATH}/majors/:id/edit`;

export const UNIVERSITIES_PATH = `${DASHBOARD_PATH}/universities`;
export const CREATE_UNIVERSITY_PATH = `${DASHBOARD_PATH}/universities/create`;
export const EDIT_UNIVERSITY_PATH = `${DASHBOARD_PATH}/universities/:id/edit`;

export const GET_COMPANIES_PATH = `${DASHBOARD_PATH}/companies`;
export const CHANGE_COMPANIES_STATUS_PATH = `${DASHBOARD_PATH}/companies/:id/change-status`;
export const ASSIGN_PLAN_PATH = `${DASHBOARD_PATH}/companies/:id/assign-plan`;