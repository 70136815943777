import { PlusOutlined } from "@ant-design/icons";
import {
  Input,
  Modal,
  notification,
  Select,
  Spin,
  Table,
  Tabs,
  TabsProps,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MajorTableCols from "components/table-columns/MajorTableCols";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createItemService } from "services/createItemService";
import {
  DELETE_MAJOR_ENDPOINT,
  GET_MAJORS_ENDPOINT,
  GET_UNIVERSITY_DROPDOWN_VALUES_ENDPOINT,
} from "api/apiUrls";
import { CREATE_MAJOR_PATH } from "Routes/paths";
import { deleteItemService } from "services/deleteItemService";
import { BaseResponse } from "../../../constants";
import { getItemsService } from "services/getItemsService";

export default function Majors() {
  const navigate = useNavigate();

  const { Search } = Input;

  const [searchParams, setSearchParams] = useSearchParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [deletingId, setDeletingId] = useState<number | null>(null);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Majors",
      children: <></>,
    },
  ];

  const deleteMutation = useMutation({
    mutationFn: () =>
      deleteItemService(
        DELETE_MAJOR_ENDPOINT(deletingId?.toString() ?? "1")
      ).then((res) => res),
    onSuccess: (data: BaseResponse) => {
      if (data.code === 1) {
        notification.error({ message: data.message });
      } else {
        notification.success({ message: data.message });
      }
    },
    onSettled: () => {
      setModalOpen(false);
      majorsQuery.mutate();
    },
  });

  const [universities, setUniversities] = useState<any>([]);
  const universitiesQuery = useQuery({
    queryKey: ["get-universities"],
    queryFn: () =>
      getItemsService(GET_UNIVERSITY_DROPDOWN_VALUES_ENDPOINT).then(
        (res) => res
      ),
  });

  const PAGE_SIZE = 8;

  const [majors, setMajors] = useState<any>([]);
  const [totalItems, setTotalItems] = useState(0);
  const majorsQuery = useMutation({
    mutationFn: () =>
      createItemService(GET_MAJORS_ENDPOINT, {
        pageNumber: Number(searchParams.get("page") ?? "1") - 1,
        pageSize: PAGE_SIZE,
        sortingExpression: "",
        sortingDirection: 0,
        majorName: searchParams.get("name") ?? "",
        universityId: searchParams.get("university") ?? "1",
      }).then((res) => res),
  });

  useEffect(() => {
    majorsQuery.mutate();
  }, [searchParams]);

  useEffect(() => {
    if (majorsQuery.data) {
      setMajors(majorsQuery.data?.data?.dataList);
      setTotalItems(majorsQuery.data?.data?.totalCount);
    }
  }, [majorsQuery.data]);

  useEffect(() => {
    if (universitiesQuery.data) {
      setUniversities(universitiesQuery.data?.data);
    }
  }, [universitiesQuery.data]);

  return (
    <section className="w-full h-full p-6 flex flex-col items-start gap-4 relative">
      <div className="w-full grid grid-cols-3 scrn-840:hidden gap-3">
        <div className="w-full">
          <Spin
            spinning={universitiesQuery.isFetching}
            wrapperClassName="spin-full-width"
          >
            <Select
              onSelect={(value) =>
                setSearchParams({
                  page: "1",
                  name: "",
                  university: value,
                })
              }
              options={universities?.map(
                (item: { name: string; id: number }) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                }
              )}
              className="min-h-[2.6rem] w-full"
              placeholder="University"
            />
          </Spin>
        </div>
        <Search
          className="!h-[2.5rem] w-full"
          placeholder="Search by id, name, or email"
          onSearch={(value) =>
            setSearchParams({
              page: searchParams.get("page") ?? "1",
              university: searchParams.get("university") ?? "1",
              name: value,
            })
          }
        />
        <button
          onClick={() => navigate(CREATE_MAJOR_PATH)}
          className="w-[2.5rem] h-[2.5rem] rounded-lg flex items-center justify-center bg-primary text-white"
        >
          <PlusOutlined className="font-bold text-white" />
        </button>
      </div>
      <div className="w-full rounded-lg shadow-md bg-white pb-2">
        <div className="w-full h-[4rem] px-4 bg-[#F9FAFB] border border-[#E6E8EC] flex flex-row items-end justify-between rounded-tr-lg rounded-tl-lg">
          <Tabs defaultActiveKey="1" items={items} />
          <div className="h-[4rem] hidden scrn-840:flex items-center justify-center gap-x-3">
            <div className="w-[18rem]">
              <Spin
                spinning={universitiesQuery.isFetching}
                wrapperClassName="spin-full-width"
              >
                <Select
                  onSelect={(value) =>
                    setSearchParams({
                      page: "1",
                      name: "",
                      university: value,
                    })
                  }
                  options={universities?.map(
                    (item: { name: string; id: number }) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    }
                  )}
                  className="min-h-[2.6rem] w-full"
                  placeholder="University"
                />
              </Spin>
            </div>
            <Search
              className="!h-[2.5rem] !w-[18rem]"
              placeholder="Search by major"
              onSearch={(value) =>
                setSearchParams({
                  page: searchParams.get("page") ?? "1",
                  university: searchParams.get("university") ?? "1",
                  name: value,
                })
              }
            />
            <button
              onClick={() => navigate(CREATE_MAJOR_PATH)}
              className="w-[2.5rem] h-[2.5rem] rounded-lg flex items-center justify-center bg-primary text-white"
            >
              <PlusOutlined className="font-bold text-white" />
            </button>
          </div>
        </div>
        <Table
          className="m-4 shadow-md overflow-x-auto"
          dataSource={majors}
          loading={majorsQuery.isPending}
          pagination={{
            current: Number(searchParams.get("page") ?? "1"),
            pageSize: 8,
            total: totalItems,
            showSizeChanger: false,
            onChange(page, pageSize) {
              setSearchParams({
                page: page.toString(),
                name: searchParams.get("name") ?? "",
                university: searchParams.get("university") ?? "1",
              });
            },
          }}
          columns={MajorTableCols(
            (value: any) => {
              navigate(`/dashboard/majors/${value}/edit`);
            },
            (value: any) => {
              setModalOpen(true);
              setDeletingId(value);
            }
          )}
        />
      </div>
      <Modal
        title="Delete Confirmation"
        open={modalOpen}
        onOk={async () => {
          deleteMutation.mutate();
        }}
        onCancel={() => setModalOpen(false)}
        okButtonProps={{
          className: "bg-[red]",
        }}
        confirmLoading={deleteMutation.isPending}
      >
        <p>Are you sure you want to delete: {deletingId}</p>
      </Modal>
    </section>
  );
}
