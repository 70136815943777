import { Loading3QuartersOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Input, notification, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
  EDIT_MAJOR_ENDPOINT,
  GET_MAJOR_ENDPOINT,
  GET_UNIVERSITY_DROPDOWN_VALUES_ENDPOINT,
} from "api/apiUrls";
import { getItemsService } from "services/getItemsService";
import { useFormik } from "formik";
import { BaseResponse, EMPTY_FIELD_ERR_MSG } from "../../../constants";
import * as yup from "yup";
import { useNavigate } from "react-router";
import { editItemService } from "services/editItemService";

interface EditMajorSchema {
  majorEnName: string;
  majorArName: string;
  universityId: number | null;
}

export default function EditMajorForm({ id }: { id: string }) {
  const navigate = useNavigate();

  const [major, setMajor] = useState<any>(null);
  const majorQuery = useQuery({
    queryKey: ["get-major", id],
    queryFn: () => getItemsService(GET_MAJOR_ENDPOINT(id)).then((res) => res),
  });

  const mutation = useMutation({
    mutationFn: (values: EditMajorSchema) =>
      editItemService(EDIT_MAJOR_ENDPOINT(id), values, "application/json").then(
        (res) => res
      ),
    onSuccess: (data: BaseResponse) => {
      if (data.code === 1) {
        notification.error({ message: data.message });
      } else {
        notification.success({ message: data.message });
        navigate(-1);
      }
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      majorEnName: major?.majorEnName,
      majorArName: major?.majorArName,
      universityId: major?.universityId,
    },
    validationSchema: yup.object({
      majorEnName: yup.string().required(EMPTY_FIELD_ERR_MSG),
      majorArName: yup.string().required(EMPTY_FIELD_ERR_MSG),
      universityId: yup.number().required(EMPTY_FIELD_ERR_MSG),
    }),
    onSubmit: (values: EditMajorSchema) => {
        mutation.mutate(values);
    },
  });

  const [universities, setUniversities] = useState<any>([]);
  const universitiesQuery = useQuery({
    queryKey: ["get-universities"],
    queryFn: () =>
      getItemsService(GET_UNIVERSITY_DROPDOWN_VALUES_ENDPOINT).then(
        (res) => res
      ),
  });

  useEffect(() => {
    if (universitiesQuery.data) {
      setUniversities(universitiesQuery.data?.data);
    }
  }, [universitiesQuery.data]);

  useEffect(() => {
    if (majorQuery.data) {
      setMajor(majorQuery.data?.data);
    }
  }, [majorQuery.data]);

  return (
    <Spin spinning={majorQuery.isPending}>
        <form
          onSubmit={formik.handleSubmit}
          className="w-full flex flex-col items-start gap-4"
        >
          <div className="w-full grid grid-cols-1 scrn-767:grid-cols-2 gap-3">
            <div className="w-full flex flex-col items-start gap-1">
              <label>Major En Name*</label>
              <Input
                name="majorEnName"
                value={formik.values.majorEnName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                status={
                  formik.touched.majorEnName && formik.errors.majorEnName
                    ? "error"
                    : ""
                }
                className="min-h-[2.6rem]"
                placeholder="Major En Name"
              />
              {formik.touched.majorEnName && formik.errors.majorEnName && (
                <p className="text-red-500 text-sm">
                  {formik.errors.majorEnName?.toString()}
                </p>
              )}
            </div>
            <div className="w-full flex flex-col items-start gap-1">
              <label>Major Ar Name*</label>
              <Input
                name="majorArName"
                value={formik.values.majorArName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                status={
                  formik.touched.majorArName && formik.errors.majorArName
                    ? "error"
                    : ""
                }
                className="min-h-[2.6rem]"
                placeholder="Major Ar Name"
              />
              {formik.touched.majorArName && formik.errors.majorArName && (
                <p className="text-red-500 text-sm">
                  {formik.errors.majorArName?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="w-full grid grid-cols-1 scrn-767:grid-cols-2 gap-3">
            <div className="w-full flex flex-col items-start gap-1">
              <label>University*</label>
              <Spin
                spinning={universitiesQuery.isFetching}
                wrapperClassName="spin-full-width"
              >
                <Select
                  value={formik.values.universityId}
                  onBlur={() => formik.setFieldTouched("universityId", true)}
                  onChange={(value) => formik.setFieldValue("universityId", value)}
                  status={
                    formik.touched.universityId && formik.errors.universityId
                      ? "error"
                      : ""
                  }
                  options={universities?.map(
                    (item: { name: string; id: number }) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    }
                  )}
                  className="min-h-[2.6rem] w-full"
                  placeholder="University"
                />
              </Spin>
              {formik.touched.universityId && formik.errors.universityId && (
                <p className="text-red-500 text-sm">
                  {formik.errors.universityId?.toString()}
                </p>
              )}
            </div>
          </div>
          <button
            type="submit"
            disabled={mutation.isPending}
            className="rounded-lg px-6 py-3 w-[8rem] bg-primary self-center text-white duration-100 hover:bg-primary/80"
          >
            {mutation.isPending ? (
              <Loading3QuartersOutlined className="animate-spin text-lg" />
            ) : (
              "Edit"
            )}
          </button>
        </form>
    </Spin>
  );
}
