const LOGIN_ENDPOINT = "Identity/api/Authentication/Login";

const GET_SUBSCRIPTION_PLANS_ENDPOINT = "BackEndApi/api/SubscriptionPlan/getAllForAdmin";
const CREATE_SUBSCRIPTION_PLAN_ENDPOINT = "BackEndApi/api/SubscriptionPlan";
const GET_SUBSCRIPTION_PLAN_ENDOPOINT = (id: string) => `BackEndApi/api/SubscriptionPlan/${id}`;
const EDIT_SUBSCRIPTION_PLAN_ENDPOINT = (id: string) => `BackEndApi/api/SubscriptionPlan/${id}`;

const GET_MAJORS_ENDPOINT = "BackEndApi/api/Major/search";
const CREATE_MAJOR_ENDPOINT = "BackEndApi/api/Major";
const GET_MAJOR_ENDPOINT = (id: string) => `BackEndApi/api/Major/${id}`;
const EDIT_MAJOR_ENDPOINT = (id: string) => `BackEndApi/api/Major/${id}`;
const DELETE_MAJOR_ENDPOINT = (id: string) => `BackEndApi/api/Major/${id}`;

const GET_UNIVERSITY_DROPDOWN_VALUES_ENDPOINT = "BackEndApi/api/University/getAll";

const GET_UNIVERSITIES_ENDPOINT = "BackEndApi/api/University/search";
const CREATE_UNIVERSITY_ENDPOINT = "BackEndApi/api/University";
const GET_UNIVERSITY_ENDPOINT = (id: string) => `BackEndApi/api/University/${id}`;
const EDIT_UNIVERSITY_ENDPOINT = (id: string) => `BackEndApi/api/University/${id}`;
const DELETE_UNIVERSITY_ENDPOINT = (id: string) => `BackEndApi/api/University/${id}`;

const GET_COMPANIES_ENDPOINT = "BackEndApi/api/Company/searchForAdmin";
const CHANGE_COMPANY_STATUS_ENDPOINT = "BackEndApi/api/Company/updateAcceptanceStatus";
const ASSIGN_PLAN_TO_COMPANY_ENDPOINT = "BackEndApi/api/SubscriptionPlan/assign";

const GET_SUBSCRIPTION_PLANS_DROPDOWN_VALUES_ENDPOINT = "BackEndApi/api/SubscriptionPlan/getAll";

export {
    LOGIN_ENDPOINT,
    GET_SUBSCRIPTION_PLANS_ENDPOINT,
    CREATE_SUBSCRIPTION_PLAN_ENDPOINT,
    GET_SUBSCRIPTION_PLAN_ENDOPOINT,
    EDIT_SUBSCRIPTION_PLAN_ENDPOINT,
    GET_MAJORS_ENDPOINT,
    GET_UNIVERSITY_DROPDOWN_VALUES_ENDPOINT,
    CREATE_MAJOR_ENDPOINT,
    GET_MAJOR_ENDPOINT,
    EDIT_MAJOR_ENDPOINT,
    GET_UNIVERSITIES_ENDPOINT,
    CREATE_UNIVERSITY_ENDPOINT,
    GET_UNIVERSITY_ENDPOINT,
    EDIT_UNIVERSITY_ENDPOINT,
    DELETE_MAJOR_ENDPOINT,
    DELETE_UNIVERSITY_ENDPOINT,
    GET_COMPANIES_ENDPOINT,
    CHANGE_COMPANY_STATUS_ENDPOINT,
    GET_SUBSCRIPTION_PLANS_DROPDOWN_VALUES_ENDPOINT,
    ASSIGN_PLAN_TO_COMPANY_ENDPOINT
}