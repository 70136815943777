import { useFormik } from "formik";
import React from "react";
import { BaseResponse, EMPTY_FIELD_ERR_MSG } from "../../../constants";
import * as yup from "yup";
import { Input, notification } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { createItemService } from "services/createItemService";
import { useNavigate } from "react-router";
import { CREATE_UNIVERSITY_ENDPOINT } from "api/apiUrls";

interface CreateUniversitySchema {
  universityEnName: string;
  universityArName: string;
}

export default function CreateUniversityForm() {
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: (values: CreateUniversitySchema) =>
      createItemService(
        CREATE_UNIVERSITY_ENDPOINT,
        values,
        "application/json"
      ).then((res) => res),
    onSuccess: (data: BaseResponse) => {
      if (data.code === 1) {
        notification.error({ message: data.message });
      } else {
        notification.success({ message: data.message });
        navigate(-1);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      universityEnName: "",
      universityArName: "",
    },
    validationSchema: yup.object({
      universityEnName: yup.string().required(EMPTY_FIELD_ERR_MSG),
      universityArName: yup.string().required(EMPTY_FIELD_ERR_MSG),
    }),
    onSubmit: (values: CreateUniversitySchema) => {
        mutation.mutate(values);
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="w-full flex flex-col items-start gap-4"
    >
      <div className="w-full grid grid-cols-1 scrn-767:grid-cols-2 gap-3">
        <div className="w-full flex flex-col items-start gap-1">
          <label>University En Name*</label>
          <Input
            name="universityEnName"
            value={formik.values.universityEnName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            status={
              formik.touched.universityEnName && formik.errors.universityEnName
                ? "error"
                : ""
            }
            className="min-h-[2.6rem]"
            placeholder="Major En Name"
          />
          {formik.touched.universityEnName &&
            formik.errors.universityEnName && (
              <p className="text-red-500 text-sm">
                {formik.errors.universityEnName}
              </p>
            )}
        </div>
        <div className="w-full flex flex-col items-start gap-1">
          <label>University Ar Name*</label>
          <Input
            name="universityArName"
            value={formik.values.universityArName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            status={
              formik.touched.universityArName && formik.errors.universityArName
                ? "error"
                : ""
            }
            className="min-h-[2.6rem]"
            placeholder="Major Ar Name"
          />
          {formik.touched.universityArName &&
            formik.errors.universityArName && (
              <p className="text-red-500 text-sm">
                {formik.errors.universityArName}
              </p>
            )}
        </div>
      </div>
      <button
        type="submit"
        disabled={mutation.isPending}
        className="rounded-lg px-6 py-3 w-[8rem] bg-primary self-center text-white duration-100 hover:bg-primary/80"
      >
        {mutation.isPending ? (
          <Loading3QuartersOutlined className="animate-spin text-lg" />
        ) : (
          "Create"
        )}
      </button>
    </form>
  );
}
