import React from 'react'
import CreateSubscriptionPlanForm from 'components/forms/subscription-plans/CreateSubscriptionPlanForm'

export default function CreateSubscriptionPlan() {
  return (
    <div className="w-full h-full p-4">
      <div className="w-full bg-white border shadow-md rounded-lg flex flex-col p-4 pb-6 gap-4">
        <h1 className="font-bold text-lg">Create Subscription Plan</h1>
        <CreateSubscriptionPlanForm />
      </div>
    </div>
  )
}
