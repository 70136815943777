import { useFormik } from "formik";
import * as yup from "yup";
import { BaseResponse, EMPTY_FIELD_ERR_MSG } from "../../../constants";
import { Input, notification } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import api from "api";
import { LOGIN_ENDPOINT } from "api/apiUrls";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "redux/types";

interface LoginSchema {
  userName: string;
  password: string;
}

export default function LoginForm() {
  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const { Password } = Input;

  const mutation = useMutation({
    mutationFn: (values: LoginSchema) =>
      api
        .post(LOGIN_ENDPOINT, {
          grantType: "password",
          userName: values.userName,
          password: values.password,
          clientId: process.env.REACT_APP_CLIENT_ID,
          clientSecret: process.env.REACT_APP_CLIENT_SECRET,
          loginMethod: 2,
        })
        .then((res) => res.data),
    onSuccess: (data: BaseResponse) => {
      if (data.code === 1) {
        notification.error({ message: data.message });
      } else {
        notification.success({ message: data.message });

        const user = data.data;

        dispatch({ type: LOGIN_SUCCESS, payload: user });
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: yup.object({
      userName: yup
        .string()
        .email("Please enter a valid email")
        .required(EMPTY_FIELD_ERR_MSG),
      password: yup.string().required(EMPTY_FIELD_ERR_MSG),
    }),
    onSubmit: (values: LoginSchema) => {
      mutation.mutate(values);
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col items-start gap-3"
    >
      <div className="flex flex-col items-start">
        <label className="text-primary text-lg">Email</label>
        <Input
          name="userName"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          status={
            formik.touched.userName && formik.errors.userName ? "error" : ""
          }
          className="w-[350px] !h-[42px]"
          placeholder="Email"
          type="email"
        />
        {formik.touched.userName && formik.errors.userName && (
          <p className="text-red-500 text-sm">{formik.errors.userName}</p>
        )}
      </div>
      <div className="flex flex-col items-start">
        <label className="text-primary text-lg">Password</label>
        <Password
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          status={
            formik.touched.password && formik.errors.password ? "error" : ""
          }
          className="w-[350px] !h-[42px]"
          placeholder="Password"
          type="password"
        />
        {formik.touched.password && formik.errors.password && (
          <p className="text-red-500 text-sm">{formik.errors.password}</p>
        )}
      </div>
      <button
        type="submit"
        disabled={mutation.isPending}
        className="w-3/4 h-[42px] rounded-md bg-primary text-white self-center transition-colors duration-200 hover:bg-primary/80"
      >
        {mutation.isPending ? (
          <Loading3QuartersOutlined className="animate-spin" />
        ) : (
          "Login"
        )}
      </button>
    </form>
  );
}
