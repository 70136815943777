import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Table, Tabs, TabsProps } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SubscriptionPlansTableCols from "components/table-columns/SubscriptionPlansTableCols";
import { useQuery } from "@tanstack/react-query";
import { getItemsService } from "services/getItemsService";
import { GET_SUBSCRIPTION_PLANS_ENDPOINT } from "api/apiUrls";
import { CREATE_SUBSCRIPTION_PLAN_PATH } from "Routes/paths";

export default function SubscriptionPlans() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Subscription Plans",
      children: <></>,
    },
  ];

  const [subscriptionPlans, setSubscriptionPlans] = useState<any>([]);
  const subscriptionPlansQuery = useQuery({
    queryKey: ["get-subscription-plans"],
    queryFn: () =>
      getItemsService(GET_SUBSCRIPTION_PLANS_ENDPOINT).then((res) => res),
  });

  useEffect(() => {
    if (subscriptionPlansQuery.data) {
      setSubscriptionPlans(subscriptionPlansQuery.data?.data);
    }
  }, [subscriptionPlansQuery.data]);

  return (
    <section className="w-full h-full p-6 flex flex-col items-start gap-4 relative">
      <div className="w-full grid grid-cols-2 scrn-840:hidden gap-3">
        <Input
          prefix={<SearchOutlined />}
          className="!h-[2.5rem] w-full"
          placeholder="Search by id, name, or email"
        />
        <button
          onClick={() => navigate(CREATE_SUBSCRIPTION_PLAN_PATH)}
          className="w-[2.5rem] h-[2.5rem] rounded-lg flex items-center justify-center bg-primary text-white"
        >
          <PlusOutlined className="font-bold text-white" />
        </button>
      </div>
      <div className="w-full rounded-lg shadow-md bg-white pb-2">
        <div className="w-full h-[4rem] px-4 bg-[#F9FAFB] border border-[#E6E8EC] flex flex-row items-end justify-between rounded-tr-lg rounded-tl-lg">
          <Tabs defaultActiveKey="1" items={items} />
          <div className="h-[4rem] hidden scrn-840:flex items-center justify-center gap-x-3">
            <Input
              prefix={<SearchOutlined />}
              className="!h-[2.5rem] !w-[18rem]"
              placeholder="Search by id, name, or email"
            />
            <button
              onClick={() => navigate(CREATE_SUBSCRIPTION_PLAN_PATH)}
              className="w-[2.5rem] h-[2.5rem] rounded-lg flex items-center justify-center bg-primary text-white"
            >
              <PlusOutlined className="font-bold text-white" />
            </button>
          </div>
        </div>
        <Table
          className="m-4 shadow-md overflow-x-auto"
          dataSource={subscriptionPlans}
          loading={subscriptionPlansQuery.isFetching}
          pagination={{
            onChange(page, pageSize) {
              setSearchParams({ ...searchParams, page: page.toString() });
            },
          }}
          columns={SubscriptionPlansTableCols((value: any) => {
            navigate(`/dashboard/subscription-plans/${value}/edit`);
          })}
        />
      </div>
    </section>
  );
}
