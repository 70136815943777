import { Logo, LogoPrimary } from "assets/icons";
import { PageBg } from "assets/images";
import LoginForm from "components/forms/auth-forms/LoginForm";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "redux/rootReducer";
import { GET_COMPANIES_PATH } from "Routes/paths";

const Login = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.authReducer);

  useEffect(() => {
    if (user !== null && user !== undefined) {
      navigate(GET_COMPANIES_PATH);
    }
  }, [user]);

  return (
    <section className="w-full h-screen grid grid-cols-2 relative">
      <div className="w-full h-full bg-primary grid place-items-center">
        <div>
          <img src={Logo} alt="intern kit logo" />
        </div>
      </div>
      <div
        className="w-full h-full grid place-items-center relative"
        style={{
          backgroundImage: `url('${PageBg}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute top-5 right-10">
          <img src={LogoPrimary} alt="intern kit logo" className="w-24" />
        </div>
        <div className="flex flex-col items-start gap-5">
          <div className="flex flex-col items-start">
            <h2 className="text-4xl font-semibold text-primary">Welcome!</h2>
            <p className="text-gray-500">please enter your details.</p>
          </div>
          <LoginForm />
        </div>
      </div>
    </section>
  );
};

export default Login;
