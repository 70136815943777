import { PlusOutlined } from "@ant-design/icons";
import { Input, Table, Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CompanyTableCols from "components/table-columns/CompanyTableCols";
import { useMutation } from "@tanstack/react-query";
import { createItemService } from "services/createItemService";
import { GET_COMPANIES_ENDPOINT } from "api/apiUrls";

export default function Companies() {
  const navigate = useNavigate();

  const { Search } = Input;

  const [searchParams, setSearchParams] = useSearchParams();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Companies",
      children: <></>,
    },
  ];

  const PAGE_SIZE = 8;

  const [companies, setCompanies] = useState<any>([]);
  const [totalItems, setTotalItems] = useState(0);
  const companiesQuery = useMutation({
    mutationFn: () =>
      createItemService(GET_COMPANIES_ENDPOINT, {
        pageNumber: Number(searchParams.get("page") ?? "1") - 1,
        pageSize: PAGE_SIZE,
        sortingExpression: "",
        sortingDirection: 0,
        companyName: searchParams.get("name") ?? "",
      }).then((res) => res),
  });

  useEffect(() => {
    companiesQuery.mutate();
  }, [searchParams]);

  useEffect(() => {
    if (companiesQuery.data) {
      setCompanies(companiesQuery.data?.data?.dataList);
      setTotalItems(companiesQuery.data?.data?.totalCount);
    }
  }, [companiesQuery.data]);

  return (
    <section className="w-full h-full p-6 flex flex-col items-start gap-4 relative">
      <Search
        className="!h-[2.5rem] !w-[18rem] scrn-840:hidden"
        placeholder="Search by id, name, or email"
        onSearch={(value) =>
          setSearchParams({
            page: searchParams.get("page") ?? "1",
            name: value,
          })
        }
      />
      <div className="w-full rounded-lg shadow-md bg-white pb-2">
        <div className="w-full h-[4rem] px-4 bg-[#F9FAFB] border border-[#E6E8EC] flex flex-row items-end justify-between rounded-tr-lg rounded-tl-lg">
          <Tabs defaultActiveKey="1" items={items} />
          <div className="h-[4rem] hidden scrn-840:flex items-center justify-center gap-x-3">
            <Search
              className="!h-[2.5rem] !w-[18rem]"
              placeholder="Search by id, name, or email"
              onSearch={(value) =>
                setSearchParams({
                  page: searchParams.get("page") ?? "1",
                  name: value,
                })
              }
            />
          </div>
        </div>
        <Table
          className="m-4 shadow-md overflow-x-auto"
          dataSource={companies}
          loading={companiesQuery.isPending}
          pagination={{
            current: Number(searchParams.get("page") ?? "1"),
            pageSize: 8,
            total: totalItems,
            showSizeChanger: false,
            onChange(page, pageSize) {
              setSearchParams({
                page: page.toString(),
                name: searchParams.get("name") ?? "",
              });
            },
          }}
          columns={CompanyTableCols(
            (companyId: any, acceptanceStatusId: any) => {
              navigate(
                `/dashboard/companies/${companyId}/change-status?acceptance_status_id=${acceptanceStatusId}`
              );
            },
            (companyId: any, subscriptionPlanId: any) => {
              navigate(
                `/dashboard/companies/${companyId}/assign-plan?plan_id=${subscriptionPlanId}`
              );
            }
          )}
        />
      </div>
    </section>
  );
}
